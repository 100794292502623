<template>
    <div>
        <div class="table_content">

            <!-- 表格内容 -->
            <commonTitle></commonTitle>
            <div class="search">
                <el-form ref="form" :model="form" label-width="120px" :inline="true">
                    <el-form-item label="业主姓名:">
                        <el-input v-model="form.name" placeholder="请输入业主姓名" size="mini"></el-input>
                    </el-form-item>
                    <button @click.prevent="sear()" style="cursor: pointer">搜索</button>
                </el-form>
            </div>
            <el-table
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    :data="tableData"
                    style="width:100%"
                    border
                    :default-sort="{prop: 'settingTime', order: 'descending'}"
                    :stripe="true">
                <el-table-column fixed prop="villageName" label="小区名称" width="150">
                </el-table-column>
                <el-table-column fixed prop="towerNum" label="楼幢号" width="150">
                </el-table-column>
                <el-table-column prop="unitName" label="单元名称" width="120">
                </el-table-column>
                <el-table-column prop="houseNum" label="户室编号" width="120">
                </el-table-column>
                <el-table-column prop="nickname" label="姓名" width="120">
                </el-table-column>
                <el-table-column prop="phone" label="电话" width="120">
                </el-table-column>
                <el-table-column prop="createTime" label="申请时间" width="160px" :formatter="formatSettingTime">

                </el-table-column>
                <el-table-column prop="status" label="审批状态" width="120" :formatter="formatStatus">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                :disabled="scope.row.status!='3'"
                                @click="pass(scope.row,1)">通过
                        </el-button>
                        <el-button
                                size="mini"
                                :disabled="scope.row.status!='3'"
                                @click="pass(scope.row,2)">驳回
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    style="margin-top: 50px"
                    layout="prev, pager, next"
                    :page-size="15"
                    @current-change="handleCurrentChange"
                    :total="totalNum">
            </el-pagination>


            <!-- 审批意见弹出框-->
            <el-dialog
                    title="请输入审批意见"
                    :visible.sync="dialogVisible"
                    width="30%">
                <el-input v-model="opinion" placeholder="请输入审批意见(非必填项)" autosize><i
                        class="el-icon-edit el-input__icon"
                        slot="suffix"
                >
                </i></el-input>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="sub">确 定</el-button>
                </span>
            </el-dialog>

        </div>
    </div>
</template>

<script>
    import qs from "qs"
    import commonTitle from "../table/commonTitle";

    export default {
        name: "ownerApply",
        components: {
            commonTitle
        },
        data() {
            return {
                tableData: [],
                url: this.$Config.base_server,
                totalNum: 15,
                dialogVisible: false,//控制弹出意见是否展示
                opinion: '', //审批意见
                row: {},//点击通过按钮  记录这一行的数据
                loading: false,
                form: {
                    name: "",
                    region: '',
                },
                status0: ""
            }
        },
        mounted() {
            this.common();
        },
        methods: {
            common(currentPage = 0) {
                this.loading = true;
                //获取登录进来时记录的标记
                const sf = localStorage.getItem("sf");
                const that = this;
                const data = new Object();
                data.access_token = localStorage.getItem("token"); //令牌
                data.page = currentPage;
                data.limit = 15;
                const userId = JSON.parse(localStorage.getItem("user")).id;
                const userType = JSON.parse(localStorage.getItem("user")).type;
                const URL = this.url + "/api-public/opeOwner/findOwnerApplys";
                $.ajax({
                    url: URL,
                    data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            that.totalNum = res.count;
                            that.tableData = res.data;
                            that.loading = false;
                        }

                    },
                    error: function (res) {
                        that.loading = false;
                    }
                })
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleCurrentChange(val) {//当前页数改变
                this.common(val)
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            }, pass(row, status) {
                //通过按钮
                this.dialogVisible = true;
                this.row = row;
                this.status0 = status;
            }, sub() {
                //弹框提交
                const data0 = {
                    id: this.row.id,
                    houseId: this.row.houseId,
                    status: this.status0,
                    ownerId:this.row.ownerId,
                    approvalOpinion: this.opinion //审批意见
                };
                const that = this;
                $.ajax({
                    url: this.url + '/api-public/wyownerapplayupdate?access_token=' + localStorage.getItem("token"),
                    data: JSON.stringify(data0),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        if (res.resp_code == 0) {
                            that.common();
                            that.$message({
                                type: 'success',
                                message: res.resp_msg
                            });
                        }
                        if (res.resp_code == 1) {
                            that.$message({
                                type: 'error',
                                message: res.resp_msg
                            });
                        }
                    }
                });
                this.dialogVisible = false; //关闭弹框
                this.opinion = ''; //审批意见置空
            }, formatStatus(row) {
                //格式化状态
                //控制审批状态
                if (row.status == '0') {
                    return '未提交';
                } else if (row.status == '1') {
                    return '审批通过';
                } else if (row.status == '2') {
                    return '审批拒绝';
                } else if (row.status == '3') {
                    return '审批中';
                }
            }, sear(currentPage = 0) {
                this.loading = true;
                var _this = this;
                var da = {
                    access_token: localStorage.getItem("token"),
                    page: currentPage,
                    limit: 15,
                    nickName: _this.form.name
                };
                $.ajax({
                    url: _this.url + "/api-public/opeOwner/findOwnerApplys",
                    data: da,
                    contentType: 'application/json',
                    type: 'get',
                    success: function (result) {
                        _this.loading = false;
                        _this.totalNum = result.count;
                        _this.tableData = result.data;
                    }
                });
            }, formatSettingTime(row) {
                //格式化时间
                // let date = new Date(row.createTime);
                // console.log(date);
                // let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
                // let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                // return date.getFullYear() + "-" + month + "-" + day;
                return row.createTime.replace(/-/g, '-').replace('T', ' ').replace('.000+0000', '')
            }
        }
    }
</script>

<style scoped>

</style>